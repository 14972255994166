<template>
    <div class="default_map_block">
        <div class="map_container w-100 h-100 d-flex align-items-center justify-content-center container-xl">
            <div v-for="(item, index) in filteredItemList" :key="index" :style="{ top: item.coordination.y + '%', left: item.coordination.x + '%' }" class="map_container_marker_block">
                <default-city-market v-if="item.type === 'text'" :item="item">
                    {{ item.name }}
                </default-city-market>
                <div v-if="item.type === 'title'" class="map_container_city_bold_block">
                    <span class="map_container_city_bold_block">
                        {{ item.name }}
                    </span>
                </div>
            </div>
            <img class="map_container_images map_container_map_state" src="@/assets/images/map/map_state.svg" alt="" />
            <img class="position-absolute map_container_images map_container_map_kz" src="@/assets/images/map/map_kz.svg" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        filteredItemList() {
            return this.itemList !== undefined && this.itemList !== null ? this.itemList.filter(item => item.coordination !== null) : [];
        },
    },
};
</script>

<script setup>
import defaultCityMarket from '~~/components/Map/Markers/DefaultCityMarket.vue';

defineProps({
    itemList: Array,
});
</script>

<style>
.map_block_content_title_block_col {
    width: 50%;
}
.default_map_block {
    position: relative;
}
.map_container {
    position: relative;
}
.map_container_map_state {
    max-height: 100%;
    object-fit: contain;
    width: 100%;
    max-width: 1620px;
    z-index: -100;
}
.map_container_map_kz {
    width: 68%;
    object-fit: contain;
    height: 100%;
    max-height: 630px;
    z-index: -10;
}
.map_container_marker_block {
    position: absolute;
    z-index: 100;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.map_container_city_bold_block {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    transform: translate(-50%, -50%);
}
</style>
