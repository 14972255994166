<template>
    <div class="map_block">
        <div class="map_block_content_title_block container-xl mb-3 mb-lg-5">
            <div class="row">
                <div class="col-md-12 col-lg-7">
                    <BlockTitle :title="contentPages?.title" class="text-lg-start" />
                </div>
                <div class="col-md-12 col-lg-5 mt-4 mt-lg-0">
                    <div class="map_block_content_title_block_col h-100 map_card_block">
                        <div class="map_block_content_card_block position-relative">
                            <div class="default_border_block map_block_border_block">
                                <div class="default_border default_border_top_left"></div>
                                <div class="default_border default_border_top_right"></div>
                                <div class="default_border default_border_bottom_left"></div>
                                <div class="default_border default_border_bottom_right"></div>
                            </div>
                            <div class="map_block_content_card">
                                {{ contentPages?.card?.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <default-map :item-list="contentPages?.points" />
        <div class="map_block_contact">
            <ConsultationBlock />
        </div>
    </div>
</template>

<script>
import DefaultMap from '~~/components/Map/DefaultMap.vue';
import BlockTitle from '~/pages/index/components/BlockTitle.vue';
import ConsultationBlock from '~/pages/index/components/ConsultationBlock.vue';

export default {
    name: 'MapSection',
    components: { ConsultationBlock, BlockTitle, DefaultMap },
    props: {
        contentPages: {
            type: Object,
        },
    },
};
</script>

<style>
.map_block {
    margin-top: 6.88rem;
    width: 100%;
}

.map_block_content_title_block_col {
    width: 100%;
}
.map_block_border_block {
    top: -12px;
    left: -12px;
}

.map_card_block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.map_block_content_card_block {
    max-width: 504px;
    min-height: 132px;
}

.map_block_content_title_block {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.map_block_content_card {
    padding: 20px 20px 44px;
    font-size: 22px;
    line-height: 34px;
    font-weight: 300;
    height: 100%;
    background: #fafafa;
    color: #2b2b2b;
}

.map_card_block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.map_block_contact {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.88rem;
}

.map_contact_button {
    font-weight: 700;
    font-size: 20px !important;
    line-height: 24px;
    padding: 20px 36px !important;
}

.map_contact_info {
    font-size: 24px;
    display: flex;
    gap: 3px;
    justify-content: center;
    flex-direction: column;
    font-weight: 700;
}

.map_contact_info_title {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
}

.map_contact_telephone_block a {
    color: #48538b;
    font-size: 24px;
    text-decoration: none;
}

@media (max-width: 1000px) {
    .map_block_content_title_block_col {
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .map_block_content_title_block_col {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 510px) {
    .map_contact_button_block {
        margin: 24px;
    }

    .map_block_contact {
        display: flex;
        flex-direction: column;
    }

    .map_contact_button_block {
        margin: 0;
    }
}

@media (max-width: 480px) {
    .map_block_contact {
        margin: 20px 0;
    }

    .map_card_block {
        display: none;
    }

    .map_contact_info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
    }

    .map_contact_button_block {
        box-sizing: border-box;
        width: 100%;
        padding: 0 16px;

        display: flex;
        justify-content: center;
    }

    .map_contact_button {
        width: 100%;
        margin-bottom: 20px;
        z-index: 10;
        font-size: 16px !important;
        padding: 16px 24px !important;
    }

    .map_block_content_title_block {
        margin: 0;
    }

    .map_block_content_title_block_col {
        padding: 0 16px;
    }

    .map_block {
        margin-top: 80px;
    }

    .map_content_block_title {
        margin-top: 0;
    }
}
</style>
